import React, { useState } from "react";
import { FaArrowRotateRight } from "react-icons/fa6";
import { Alert, Button, TextField } from "@mui/material"
import { useTranslation } from "react-i18next";

export default function Captcha({ success, setSuccess }) {
    const randomString = Math.random().toString(36).slice(8)
    const [captcha, setCaptcha] = useState(randomString)
    const [text, setText] = useState("")
    const [valid, setValid] = useState(false)

    const refreshString = () => {
        setCaptcha(Math.random().toString(36).slice(8))
    }

    const { t } = useTranslation()

    const matchCaptcha = (event) => {
        event.preventDefault()
        if (text === captcha) {
            setValid(false)
            setSuccess(true)
        }
        else {
            setValid(true)
            setSuccess(false)
        }
        setText("")
    }

    return (
        <div className="absolute md:w-[40%] md:top-[1360px]  w-[150%] sm:top-[1820px] top-[1850px]">
            {success && (
                <div className="md:w-[30%] w-[30%]">
                    <Alert variant="outlined" size="small" sx={{ "marginBottom": "10px", "padding": 0, "paddingLeft": '10px' }}>{t("Qabul qilindi")}</Alert>
                </div>
            )}
            {!success && (
                <>
                    <div className="w-[30%] mb-3 flex justify-between border ">
                        <h1 className="pl-2 w-[70%] text-black m-2 pr-0 bg-white text-[25px] font-rubic">{captcha}</h1>
                        <FaArrowRotateRight onClick={() => refreshString()} className="bg-blue-400 text-white m-2 ml-0 w-[20%] h-[40px] p-2 hover:bg-blue-800 cursor-pointer" />
                    </div>
                    <form className="flex flex-col w-[30%]" onSubmit={matchCaptcha}>

                        <TextField value={text} focused label={t("Kodni kiriting")} size="small" error={valid} onChange={(e) => setText(e.target.value)} helperText={valid && "Kod noto'g'ri"} />

                        <div className="w-[20%]">
                            <Button variant="contained" color="success" size="small" type="submit" sx={{ "marginTop": "5px", "textTransform": "capitalize" }}>
                                {t("Tekshirish")}
                            </Button>
                        </div>
                    </form>
                </>

            )}
        </div>
    )
}
