import React from "react";
import Form from "./components/Form";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Info from "./components/Info";
import NotFound from "./components/NotFound";
import Marquee from "react-fast-marquee";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';



i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['uz', 'ru', 'kr'],
    fallbackLng: "uz",
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'subdomain', 'path'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/asets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false
    }
  });


const App = () => {
  const { t } = useTranslation()

  return (

    <div>
      <Marquee className="py-2">
        {/* <p style={{ color: "red" }}>Sayt test rejimida ishlamoqda </p> */}
        <p style={{ color: "red" }}>{t('Sayt test rejimida ishlayapdi')}</p>
      </Marquee>
      <Header />
      <Routes>
        <Route path="/" element={<Info />} />
        <Route path="form/:title/:title_for_id" element={<Form />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
