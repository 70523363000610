import React, { useEffect, useState } from "react";
import Accardion from "./Accardion";
import axios from "axios";
import { BASE_URL } from "../utils/urls";
import { useTranslation } from "react-i18next";
import { Cookies } from 'react-cookie';
import i18next from "i18next";

const Info = () => {

  const [openId, setId] = useState(null);
  const [vacancy, setVacancy] = useState([]);
  const { t } = useTranslation()
  const cookies = new Cookies();
  const lang = cookies.get('i18next')
  const [active, setActive] = useState("uz")
  useEffect(() => {
    axios
      .get(`${BASE_URL}/vacancy/${lang}`)
      .then((response) => {
        setVacancy(response.data);
	cookies.set("vacancies", response.data)      
          })
      .catch((err) => console.log(err));
  }, [lang]);

  return (

    <div className="my-6 flex items-center justify-center ">
   
      <div className="2xl:w-[50%] md:w-[70%] w-[95%] rounded-lg shadow bg-white p-4 px-6">
        <h1 className="text-center md:text-3xl text-xl py-5">
          {t("Kadrlar zahirasiga onlayn ro'yxatdan o'tish")}
        </h1>

        {vacancy.map((data, id) => (
          <Accardion
            onClick={() => (id === openId ? setId(null) : setId(id))}
            data={data}
            isOpen={id === openId}
            key={id}
          />
        ))}
      </div>
    </div>

  );
};

export default Info;
