import React, {useState, useEffect}  from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Cookies } from 'react-cookie';
import { BASE_URL } from "../utils/urls";
import axios from "axios";

const AccardionBody = ({ data }) => {
  const { t } = useTranslation()

  return (
    <>



	  {data?.subtitle ?  <div className="my-3 mt-0">

		    <p className="bg-white text-dark py-[1px] pl-3">
         		 {data.subtitle.split(".")[0]}
       		    </p>
		    <p className="bg-white text-dark py-[1px] pl-3">
         		 {data.subtitle?.split(".")[1]}
       		    </p>
		      <p className="bg-white text-dark py-[1px] pl-3">
       		   {data.subtitle?.split(".")[2]}
      		  </p>
      			  <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[3]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[4]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[5]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[6]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[7]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[8]}
        </p>
        <p className="bg-white text-dark py-[1px] pl-3">
          {data.subtitle?.split(".")[9]}
        </p>
       		
			  </div> : ""}

		

      <div className="flex md:flex-row flex-col justify-between md:pr-7">
        <div className="shadow-xl  bg-white min-w-[48%] rounded-xl md:rounded-xl pb-2 mb-3 md:mr-3">
          <div className="vacant-item-header font-bold text-[15px] md:text-[17px] text-white text-center w-full roundeed-xl border py-4">
            {t("Talablar")}
          </div>

          <div className="p-4 x  rounded-b-xl md:text-[15px] text-[13px] ">
            {data.requirements}
          </div>
        </div>
        <div className="shadow-xl bg-white min-w-[50%] rounded-xl md:rounded-xl pb-2 mb-3 md:ml-3">
          <div className="vacant-item-header font-bold text-[15px] md:text-[17px] text-white text-center w-full roundeed-xl border py-4">

            {t("Mas'uliyat")}
          </div>

          <div className="p-4 bg-white rounded-b-xl  md:text-[15px] text-[13px] ">
            {data.responsibility}
          </div>
        </div>
      </div>
      <div className="md:my-7 mt-5 flex justify-center">
        <Link to={`/form/${data.title_for_id}/${data.title}`}
          type="submit"
          className="block md:w-[25%] rounded-md bg-blue-800 px-3.5 py-2.5 text-center md:text-[16px] text-[14px] font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {t("Murojaat qilish")}
          
        </Link>
      </div>
    </>
  );
};

export default AccardionBody;

