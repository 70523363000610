import React, { useState, useTransition } from "react";
import { bnpz, pdf, ung } from "../assets/images";
import { GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation()
  const [active, setActive] = useState("uz")

  return (
    <>
      <Link
        to={"/"}
        className="flex flex-row justify-center px-6 pt-6 md:mb-[50px] mb-[20px]"
      >
        <img src={ung} className="md:h-[170px] h-[90px] " alt="ung" />
        <div className="flex flex-row">
          <img src={bnpz} className="md:h-[170px] h-[90px] " alt="bnpz" />
        </div>
      </Link>

      <div className=" mt-10 flex items-center justify-center">
        <div className="2xl:w-[50%] md:w-[70%] w-[80%] flex items-center justify-between ">
          <a
            className="flex items-center hover:text-sky-500 transition-all duration-150"
            href="https://bnpz.uz/"
          >
            <GrLinkPrevious className="md:text-[15px] text-[13px]" />
            <p className="font-sans pl-2 md:text-[15px] text-[13px]">
              {t("veb saytga qaytish")}
            </p>
          </a>

          <p className="md:text-[15px] text-[12px] flex justify-between items-center md:w-[35%] ">
            <a href="https://weboffice.bnpz.uz/Products/Files/DocEditor.aspx?fileid=385&doc=WFIxTHFSeVNJdVpVNXdMdFJGT2M5RVN1dTBsU21qY011QmNBbzlmTnBCWT0_IjM4NSI1"
              target="_blank" className="flex justify-between items-center w-[32%] md:mx-0 mx-3"
            >
              <img src={pdf} className="md:w-7 w-4" alt="" />
              <em>{t("Qo'llanma")}</em>
            </a>
            
            <div className="flex items-center">
              <a
                className={`md:px-3 px-1 md:text-[16px] text-[13px] hover:text-gray-500 cursor-pointer ${active === "uz" ? "text-gray-500" : ""}`}
                onClick={() => {
                  i18next.changeLanguage("uz")
                  setActive("uz")
                }}
              >O'z</a>
              <a
                className={`md:px-3 px-1 md:text-[16px] text-[13px] hover:text-gray-500 cursor-pointer ${active === "ru" ? "text-gray-500" : ""}`}
                onClick={() => {
                  i18next.changeLanguage("ru")
                  setActive("ru")
                }}
              >Ru</a>
              <a
                className={`md:px-3 px-1 md:text-[16px] text-[13px] hover:text-gray-500 cursor-pointer ${active === "kr" ? "text-gray-500" : ""}`}
                onClick={() => {
                  i18next.changeLanguage("kr")
                  setActive("kr")
                }}
              >Уз</a>
            </div>

          </p>
        </div>
      </div>
    </>
  );
}

export default Header;
