import { React, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import AccardionBody from "./AccardionBody";
import i18next from "i18next";
import { useTranslation } from "react-i18next";


const Accardion = ({ data, isOpen, onClick }) => {
  const itemRef = useRef(null);
  const { t } = useTranslation()
  const [active, setActive] = useState("uz")

  return (

    
    <div className="rounden-[15px] border w-full my-6 md:py-6 py-2">
  
      <div
        className={` w-full py-3 pt-1  px-5 flex justify-between items-center cursor-pointer ${!isOpen ? "hover:bg-backGtl" : "hover:bg-white"
          }`}
        onClick={() => onClick()}
      >
        <div className="md:accardion-header accardion-header-2">
          <h1 className="md:text-[25px] text-[18px] font-bold">{data.title}</h1>
          {/* <p className="pt-4 md:text-[16px] text-[">{data.description}</p> */}
        </div>

        <HiChevronDown
          className={`md:text-[30px] text-[20px]
          ${isOpen ? "active" : ""}`}
        />
      </div>
      <div
        className="accardion-collaps"
        style={
          isOpen ? { height: itemRef.current.scrollHeight } : { height: "0px" }
        }
      >
        <div className="md:accardion-body p-[20px] bg-slate-100" ref={itemRef}>
          <AccardionBody data={data} />
        </div>
      </div>
    </div>
  );
};

export default Accardion;
